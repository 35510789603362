import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// COMPONENTS
import Layout from '../components/layout'
import { PricingTiers } from '../components/pricing-tiers'
import SavingsCalculator from '../components/savings-calculator'
import RequestDemoForm from '../components/request-demo-form'

// IMAGES
import wave from '~images/wave-1-bottom-sm.svg'
import app from '~images/app_development.svg'
import comment from '~images/comment.svg'
import iphone from '~images/iphone-modern.svg'
import circle from '~images/half-circle-2QVR.svg'
import CombinedPricingElements from '../components/combined-pricing-elements'
// LOGOS
import logoTeal from '~images/qvr_logo_teal_black.svg'
import exampleLogo from '~images/logo-airbnb.svg'
import rhe from '~images/rhe.svg'
import whipplePoint from '~images/whipple-point.svg'
import buddies4Life from '~images/buddies-4-life.svg'
import elevatedCare from '~images/elevated-care.svg'
import launchServices from '~images/launch-services.svg'
import mrsh from '~images/mrsh.svg'
import thryve from '~images/thryve.svg'
import fiveStars from '~images/five-stars.svg'
import abbyLou from '~images/abby-lou.png'


const monthlyPrice = {
  arrow: '10',
  village: '15',
  castle: '18',
  type: 'per month'
}

const yearlyPrice = {
  arrow: '50',
  village: '150',
  castle: '290',
  type: 'a year'
}

const Pricing = () => {
  const img = useStaticQuery(graphql`
  query {
    bluelogo: file(relativePath: { eq: "logo_bluestep.png"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  const [periodMonthly, setPeriodMonthly] = useState(true)
  const [price, setPrice] = useState(monthlyPrice)
  const [pricingData, setPricingData] = useState({ selectedPackage: 'arrow', numClients: 50, implementationPrice: 0, price: 400 })
  const changePrice = period => {
    if (period === 'month') {
      setPrice(monthlyPrice)
      setPeriodMonthly(true)
    } else {
      setPrice(yearlyPrice)
      setPeriodMonthly(false)
    }
  }
  return (
    <Layout>
      <main id="content" role="main">
        {/* Hero Section */}
        <div id="SVGwave1BottomSMShape" className="position-relative gradient-half-qvr-v1">
          <div className="container space-top-2 space-top-md-4 space-bottom-4">
            <div className="w-md-80 w-lg-60 text-center mx-auto">
              <div className="mb-6">
                <h1 className="text-white">
                  The <span className="font-weight-semi-bold">right</span> compliance software<br />
                  shouldn't cost you a <span className="font-weight-semi-bold">Dime!</span>
                </h1>
              </div>

              {/* Button Group */}
              {/* <div className="btn-group btn-group-toggle mb-4">
                <button className={
                  periodMonthly
                    ? `js-animation-link btn btn-outline-light btn-custom-toggle-white btn-sm-wide active`
                    : `js-animation-link btn btn-outline-light btn-custom-toggle-white btn-sm-wide`
                }
                  onClick={() => changePrice('month')}
                >
                  Monthly
                </button>
                <button className={
                  periodMonthly
                    ? `js-animation-link btn btn-outline-light btn-custom-toggle-white btn-sm-wide`
                    : `js-animation-link btn btn-outline-light btn-custom-toggle-white btn-sm-wide active`
                }
                  onClick={() => changePrice('year')}
                >
                  Yearly
                  <span className="badge badge-success badge-pill badge-bigger badge-pos">17% OFF</span>
                </button>
              </div> */}
              {/* End Button Group */}
            </div>
          </div>

          {/* SVG Background */}
          <figure className="position-absolute right-0 bottom-0 left-0">
            <img src={wave} alt="wave" style={{ width: '100%', height: '140px' }} />
          </figure>
          {/* End SVG Background */}
        </div>
        {/* End Hero Section */}

        

        {/* Subscribe Section */}
        <div id="SVGsubscribe" className="overflow-hidden">
          <div className="position-relative  overflow-hidden space-top-2 space-bottom-3 space-top-md-3 space-bottom-md-4">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-10 col-lg-12 col-xl-12">
                  {/* Title */}
                  <div className="text-center mb-2">
                    <h2 className="h1 text-black">Request a <span className="font-weight-semi-bold">Free</span> Guided Demo</h2>
                  </div>
                  {/* End Title */}

                  <RequestDemoForm destination={'sales'}></RequestDemoForm>

                  {/* Info */}
                  <div className="d-block d-xl-none">
                    <small className="form-text text-black-70 mb-0">Be the lucky user to earn <span className="text-black font-weight-semi-bold">$1000</span> bonus now!</small>
                  </div>
                  {/* End Info */}
                </div>

              </div>
            </div>

            {/* SVG Phone Mockup */}
            {/* <div className="d-none d-lg-block u-devices-v2__phone u-devices-v2__phone--left-position">
              <figure className="w-75 u-devices-v2__phone-svg ie-devices-v2-iphone">
                <img className="js-svg-injector" src={iphone} alt="iphone" />
              </figure>
            </div> */}
            {/* End SVG Phone Mockup */}
          </div>

          {/* SVG Background */}
          <figure className="position-absolute right-0 bottom-0 left-0 z-index-2">
            <img className="js-svg-injector" src={wave} alt="wave" style={{ width: '100%', height: '140px' }} />
          </figure>
          {/* End SVG Background Section */}
        </div>
        {/* End Subscribe Section */}
      </main>
    </Layout>
  )
}

export default Pricing
