import React, { useState, useEffect } from 'react'
import CalculatorForm from './savings-calculator-form'
import PackageSelection from './package-selection';
import Slider from 'react-rangeslider'

import { Link } from 'gatsby'

// Images
import icon13 from '~images/icon-13.svg'

function CombinedPricingElements() {

  const [numClients, setNumClients] = useState(50)
  const [price, setPrice] = useState(400)
  const [implementationPrice, setImplementationPrice] = useState(2500)
  const [selectedPackage, setSelectedPackage] = useState('arrow')
  const [userType, setUserType] = useState('Users')
  
  const [emarTotal, setEmarTotal] = useState(0)
  const [hrmTotal, setHrmTotal] = useState(0)
  const [ehrTotal, setEhrTotal] = useState(0)
  const [currentCosts, setCurrentCosts] = useState(0)
  const [empCost, setEmpCost] = useState(0)
  const [qvrPerc, setQvrPerc] = useState(50)
  const [empPerc, setEmpPerc] = useState(50)
  const [emarPerc, setEmarPerc] = useState(33)
  const [hrmPerc, setHrmPerc] = useState(33)
  const [ehrPerc, setEhrPerc] = useState(34)
  const [totalSavings, setTotalSavings] = useState(0)

  const [yearly, setYearly] = useState(false)


  useEffect(() => {
    if (selectedPackage === 'arrow') {
      if (!yearly) {
        setUserType('Users')
        let price = numClients * 8;
        setPrice(price)
        setImplementationPrice(0)
      } else {
        setUserType('Users')
        let price = numClients * 6;
        setPrice(price)
        setImplementationPrice(0)
      }
    } else if (selectedPackage === 'village' || selectedPackage === 'castle') {
      if (!yearly) {
        setUserType('Clients')
        if (numClients <= 50) {
          setPrice(800)
          setImplementationPrice(2500)
        } else if (numClients > 50 && numClients <= 100) {
          setPrice(1500)
          setImplementationPrice(3500)
        } else if (numClients > 100 && numClients <= 200) {
          setPrice(2800)
          setImplementationPrice(4500)
        } else if (numClients > 200 && numClients <= 400) {
          setPrice(5400)
          setImplementationPrice(5500)
        } else if (numClients > 400 && numClients <= 600) {
          setPrice(7200)
          setImplementationPrice(6500)
        } else if (numClients > 600 && numClients <= 800) {
          setPrice(8800)
          setImplementationPrice(8000)
        } else if (numClients > 800 && numClients <= 1000) {
          setPrice(10500)
          setImplementationPrice(0)
        } else {
          setPrice(800)
          setImplementationPrice(2500)
        }
          
      } else {
        setUserType('Clients')
        if (numClients <= 50) {
          setPrice(700)
          setImplementationPrice(2500)
        } else if (numClients > 50 && numClients <= 100) {
          setPrice(1300)
          setImplementationPrice(3500)
        } else if (numClients > 100 && numClients <= 200) {
          setPrice(2500)
          setImplementationPrice(4500)
        } else if (numClients > 200 && numClients <= 400) {
          setPrice(5000)
          setImplementationPrice(5500)
        } else if (numClients > 400 && numClients <= 600) {
          setPrice(6800)
          setImplementationPrice(6500)
        } else if (numClients > 600 && numClients <= 800) {
          setPrice(8500)
          setImplementationPrice(8000)
        } else if (numClients > 800 && numClients <= 1000) {
          setPrice(10200)
          setImplementationPrice(0)
        } else {
          setPrice(800)
          setImplementationPrice(2500)
        }
      }
      // } else if (selectedPackage === 'castle') {
    };


    let sum = emarTotal + hrmTotal + ehrTotal
    let emar = (emarTotal / sum) * 100
    let hrm = (hrmTotal / sum) * 100
    let ehr = (ehrTotal / sum) * 100
    let emp = (sum * 0.2)

    let qvrSum = (price + emp)
    let qvr = (price / (qvrSum)) * 100
    let qvrEmp = (emp / qvrSum) * 100
    let combinedTotal = (price + (sum * .2))

    let savings = (sum - combinedTotal)

    savings >= 0 ? setTotalSavings(savings) : setTotalSavings(0);

    // let sav = (overalTotal > 0 ? overalTotal - combinedTotal : 0)
    // let savPerc = (overalTotal > 0 ? overalTotal / (overallTotal + combinedTotal) : 0)
    // setTotalSavings(sav)
    // setTotalSavingsPerc(savPerc)

    setEmarPerc(emar)
    setHrmPerc(hrm)
    setEhrPerc(ehr)
    setCurrentCosts(sum)
    setQvrPerc(qvr)
    setEmpPerc(qvrEmp)
    setEmpCost(emp)

  }, [emarTotal, hrmTotal, ehrTotal, numClients, selectedPackage, yearly ])

  return (
    <>

      {/* Pricing Section */}
      <div className="position-relative mt-n23 z-index-2">
        <div className="container">
          <div className="space-bottom-2">
            <div className="container space-2">
              {/* Card Group */}
              <div className="card-group card-group-md-break rounded shadow-soft">
                <div className="card">
                  <div className="card-body text-center pt-9 px-7">
                    {/* Button Group */}
                    <div className="btn-group btn-group-toggle mb-7">
                      <a
                        className={
                          yearly
                            ? `js-animation-link btn btn-sm btn-outline-secondary btn-custom-toggle-primary btn-pill`
                            : `js-animation-link btn btn-sm btn-outline-secondary btn-custom-toggle-primary btn-pill active`
                        }
                        href="javascript:;"
                        data-target="#pricingMonthly"
                        data-link-group="idPricing"
                        onClick={e => setYearly(false)}
                      >
                        Monthly
                      </a>
                      {/* <a
                        className={
                          yearly
                            ? `js-animation-link btn btn-sm btn-outline-secondary btn-custom-toggle-primary btn-pill active`
                            : `js-animation-link btn btn-sm btn-outline-secondary btn-custom-toggle-primary btn-pill`
                        }
                        href="javascript:;"
                        data-target="#pricingYearly"
                        data-link-group="idPricing"
                        onClick={e => setYearly(true)}
                      >
                        Yearly
                        <span className="badge badge-success badge-pill badge-pos">
                          Save 20%
                        </span>
                      </a> */}
                    </div>
                    {/* End Button Group */}

                    {/* Monthly Plans */}
                    <div id="pricingMonthly" data-target-group="idPricing">
                      {/* Range Slider */}
                      <div className="text-secondary font-weight-semi-bold mb-4">
                        <span
                          id="rangeSliderSecondaryResult1"
                          className="mr-2"
                        ></span>
                        <span className="ml-n2">{numClients} {userType}</span>
                      </div>
                      <div className="display-4 text-primary font-weight-semi-bold mb-5">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(price)}
                      </div>
                      <div className="ml-n2"> +
                        {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }).format(implementationPrice)} Implementation Cost
                      </div>
                      <Slider
                        min={10}
                        max={1000}
                        step={10}
                        value={numClients}
                        onChange={value => setNumClients(value)}
                      />
                      {/* End Range Slider */}
                    </div>
                  </div>
                  <div className="card-footer border-0 pt-0 pb-9 px-7">
                    {/* Icon Blocks */}
                    <div className="media align-items-center">
                      <figure
                        id="icon13"
                        className="svg-preloader ie-height-56 w-100 max-width-8 mr-4"
                      >
                        <img src={icon13} />
                      </figure>
                      <div className="media-body">
                        <h4 className="h6 mb-1">Have 1000+ Clients?</h4>
                        <p className="small mb-0">
                          <Link to='pricing-contact' state={{clientAmt: numClients }}>Contact us </Link>
                          {/* <a
                            className="font-size-1"
                            href="../pages/contacts-start-up.html"
                          >
                            Contact us
                          </a>{" "} */}
                          for bigger user plan, and enterprise security features.
                        </p>
                      </div>
                    </div>
                    {/* End Icon Blocks */}
                  </div>
                </div>

                <div className="card">
                  <div className="card-body py-9 px-7">

                    {/* Icon Blocks */}
                    <div className="media align-items-center border-bottom pb-4 mb-4">
                      <div className="media-body">
                        <div className="custom-control custom-radio custom-control-inline checkbox-outline">
                          <input type="radio" id="typeOfListingRadio1" name="typeOfListingRadio1" className="custom-control-input checkbox-outline__input" onClick={() => setSelectedPackage('arrow')} checked={selectedPackage === 'arrow'} />
                          <label className="checkbox-outline__label transition-3d-hover rounded py-3 px-1 mb-0" for="typeOfListingRadio1">
                            <div className="media align-items-center">
                              <figure className="ie-height-56 w-100 max-width-8 mr-4">
                                <img src={icon13} />
                              </figure>
                              <div className="media-body">
                                <h3 className="h5">Arrow</h3>
                                <p className="mb-0">
                                  Payroll reporting, billing, scheduling and timecard solution for group home and other disability service providers
                                </p>
                              </div>
                            </div>
                          </label>
                        </div>

                      </div>
                    </div>
                    {/* End Icon Blocks */}

                    {/* Icon Blocks */}
                    <div className="media align-items-center border-bottom pb-4 mb-4">
                      <div className="media-body">
                        <div className="custom-control custom-radio custom-control-inline checkbox-outline">
                          <input type="radio" id="typeOfListingRadio2" name="typeOfListingRadio1" className="custom-control-input checkbox-outline__input" onClick={() => setSelectedPackage('village')} checked={selectedPackage === 'village'}/>
                          <label className="checkbox-outline__label transition-3d-hover rounded py-3 px-1 mb-0" for="typeOfListingRadio2">
                            <div className="media align-items-center">
                              <figure className="ie-height-56 w-100 max-width-8 mr-4">
                                <img src={icon13} />
                              </figure>
                              <div className="media-body">
                                <h3 className="h5">Village</h3>
                                <p className="mb-0">
                                  Arrow + QVR configuration for group home and community based programs
                                </p>
                              </div>
                            </div>
                          </label>
                        </div>

                      </div>
                    </div>
                    {/* End Icon Blocks */}

                    {/* Icon Blocks */}
                    <div className="media align-items-center pb-4 mb-4">
                      <div className="media-body">
                        <div className="custom-control custom-radio custom-control-inline checkbox-outline">
                          <input type="radio" id="typeOfListingRadio3" name="typeOfListingRadio1" className="custom-control-input checkbox-outline__input" onClick={() => setSelectedPackage('castle')} checked={selectedPackage === 'castle'}/>
                          <label className="checkbox-outline__label transition-3d-hover rounded py-3 px-1 mb-0" for="typeOfListingRadio3">
                            <div className="media align-items-center">
                              <figure className="ie-height-56 w-100 max-width-8 mr-4">
                                <img src={icon13} />
                              </figure>
                              <div className="media-body">
                                <h3 className="h5">Castle</h3>
                                <p className="mb-0">
                                  Arrow + Village + QVR's medical assessment technology, task analysis tools and Premier QBxMS. 
                                </p>
                              </div>
                            </div>
                          </label>
                        </div>

                      </div>
                    </div>
                    {/* End Icon Blocks */}

                  </div>
                </div>
              </div>
              {/* End Card Group */}
            </div>
          </div>
        </div>
      </div>
      {/* End Pricing Section */}

      <div className="container space-2">
        <div className="row justify-content-center">
          <div className="col-lg-10 mt-lg-n11">
            <h2 className="h1 font-weight-medium mb-4">The primary objective of QVR is to automate processes that do not require a human</h2>
            <p>
              For example, how much time do you, or an employee of yours, spend auditing client files? How much time do you or an employee spend verifying that a MAR was signed correctly. What about HR files and general compliance? With sofisticated software that is constantly checking and re-checking your files, the amount of time your administrative team spends on these tasks will be significantly reduced. Thus, when using this calculator, think of your team and the amount of time they spend doing tasks that QVR can do for you.
              </p>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="card bg-light p-5">
          {/* Title */}
          <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-2">
            <h2 className="text-primary">Monthly Savings <span className="font-weight-semi-bold">calculator</span>:</h2>

            {/* TODO: The primary objective of QVR is to automate processes that do not require a human. For example, how much time do you, or an employee of yours, spend auditing client files? How much time do you or an employee spend verifying that a MAR was signed correctly. What about HR files and general compliance? With sofisticated software that is constantly checking and re-checking your files, the amount of time your administrative team spends on these tasks will be significantly reduced. Thus, when using this calculator, think of your team and the amount of time they spend doing tasks that QVR can do for you. */}
          </div>
          {/* End Title */}

          {/* Stats */}
          <div className="row mb-2 mb-lg-0">
            <div className="col-lg-9 mb-2 mb-lg-0">
              {/* E.M.A.R. Title */}
              <div className="row">
                <div className="col">
                  <h4 className="text-primary">Your E.M.A.R. <span className="font-weight-semi-bold">Costs</span></h4>
                </div>
              </div>
              {/* End E.M.A.R. Title */}

              {/* Calculator Form */}
              <CalculatorForm fieldData={setEmarTotal} />
              {/* End Calculator Form */}

              {/* H.R.M Title */}
              <div className="row">
                <div className="col">
                  <h4 className="text-primary">Your H.R.M. <span className="font-weight-semi-bold">Costs</span></h4>
                </div>
              </div>
              {/* End H.R.M Title */}

              {/* Calculator Form */}
              <CalculatorForm fieldData={setHrmTotal} />
              {/* End Calculator Form */}

              {/* E.H.R Title */}
              <div className="row">
                <div className="col">
                  <h4 className="text-primary">Your E.H.R. <span className="font-weight-semi-bold">Costs</span></h4>
                </div>
              </div>
              {/* End E.H.R Title */}

              {/* Calculator Form */}
              <CalculatorForm fieldData={setEhrTotal} />
              {/* End Calculator Form */}

              {/* Package Selector */}
              <div className="row">
                <div className="col">
                  <h4 className="text-primary">Your QVR <span className="font-weight-semi-bold">Packages</span></h4>
                </div>
              </div>
              {/* End Package Selector */}

              {/* Calculator Form */}

              {/* Package Selection */}
              <div className="row">
                <div className="col-xl-3 col-lg-6 mb-4">
                  {/* Input */}
                  <label className="form-label">Number of {userType}</label>
                  <div className="js-focus-state input-group">
                    <input className="form-control" type="number" value={numClients} onChange={e => setNumClients(e.target.value)} placeholder='Clients' />
                  </div>
                  {/* End Input */}
                </div>

                <div className="col-xl-3 col-lg-6 mb-4">
                  {/* Button */}
                  <label className="form-label">Employee Managment</label>
                  <button type="button" onClick={value => setSelectedPackage('arrow')} className={
                    selectedPackage === 'arrow'
                      ? `btn btn-md btn-block btn-soft-primary transition-3d-hover active`
                      : `btn btn-md btn-block btn-soft-primary transition-3d-hover`
                  }
                  >Arrow</button>
                  {/* End Button */}
                </div>

                <div className="col-xl-3 col-lg-6 mb-4">
                  {/* Button */}
                  <label className="form-label">Most Popular</label>
                  <button type="button" onClick={value => setSelectedPackage('village')} className={
                    selectedPackage === 'village'
                      ? `btn btn-md btn-block btn-soft-primary transition-3d-hover active`
                      : `btn btn-md btn-block btn-soft-primary transition-3d-hover`
                  }
                  >Village</button>
                  {/* End Button */}
                </div>

                <div className="col-xl-3 col-lg-6 mb-4">
                  {/* Button */}
                  <label className="form-label">Best Value</label>
                  <button type="button" onClick={value => setSelectedPackage('castle')} className={
                    selectedPackage === 'castle'
                      ? `btn btn-md btn-block btn-soft-primary transition-3d-hover active`
                      : `btn btn-md btn-block btn-soft-primary transition-3d-hover`
                  }
                  >Castle</button>
                  {/* End Button */}
                </div>
              </div>
              {/* End Package Selection */}

              {/* End Calculator Form */}
            </div>

            <div className="col-lg-3">
              <div className="mb-4">
                <span className="h4 font-weight-medium">Current Costs {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                  }).format(currentCosts)}
                </span>
                <span className="h6 font-weight-medium">/Month</span>
              </div>
              {/* Progress */}
              <div className="progress mb-3" style={{ height: `.5rem` }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${emarPerc}%`, ariaValuenow: `15`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${hrmPerc}%`, ariaValuenow: `30`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
                <div className="progress-bar bg-info" role="progressbar" style={{ width: `${ehrPerc}%`, ariaValuenow: `20`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
              </div>
              {/* End Progress */}

              {/* Fact List */}
              <ul className="list-unstyled font-size-1 mb-0">
                <li className="d-flex align-items-center py-1">
                  <small className="far fa-circle text-primary mr-2"></small>
                  <span className="font-weight-medium">E.M.A.R.</span>
                  <div className="ml-auto">
                    <span className="text-secondary">${(emarTotal).toFixed(2)} /mo</span>
                  </div>
                </li>

                <li className="d-flex align-items-center py-1">
                  <small className="far fa-circle text-success mr-2"></small>
                  <span className="font-weight-medium">H.R.M.</span>
                  <div className="ml-auto">
                    <span className="text-secondary">${(hrmTotal).toFixed(2)} /mo</span>
                  </div>
                </li>

                <li className="d-flex align-items-center py-1">
                  <small className="far fa-circle text-info mr-2"></small>
                  <span className="font-weight-medium">E.H.R.</span>
                  <div className="ml-auto">
                    <span className="text-secondary">${(ehrTotal).toFixed(2)} /mo</span>
                  </div>
                </li>
              </ul>
              {/* End Fact List */}

              <hr />

              {/* Savings Bar */}
              <div className="mt-6 mb-4">
                <span className="h4 font-weight-medium">QVR Costs {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                  }).format(price + (currentCosts * .2))}
                </span>
                <span className="h6 font-weight-medium">/Month</span>
              </div>

              {/* Progress */}
              <div className="progress mb-3" style={{ height: `.5rem` }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${qvrPerc}%`, ariaValuenow: `50`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${empPerc}%`, ariaValuenow: `50`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
              </div>
              {/* End Progress */}

              {/* Fact List */}
              <ul className="list-unstyled font-size-1 mb-0">
                <li className="d-flex align-items-center py-1">
                  <small className="far fa-circle text-primary mr-2"></small>
                  <span className="font-weight-medium">Software Cost</span>
                  <div className="ml-auto">
                    <span className="text-secondary">{new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(price)} /mo</span>
                  </div>
                </li>

                <li className="d-flex align-items-center py-1">
                  <small className="far fa-circle text-indigo mr-2"></small>
                  <span className="font-weight-medium">Employee Cost*</span>
                  <div className="ml-auto">
                    <span className="text-secondary">{new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(empCost)} /mo</span>
                  </div>
                </li>
              </ul>
              {/* End Fact List */}
              {/* End Savings Bar */}
              <hr />
              <div className="mt-6">
                <span className="h4 font-weight-medium">Total Savings {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }).format(totalSavings)}</span>
                <span className="h6 font-weight-medium">/Month</span>
              </div>
            </div>
          </div>
          {/* End Stats */}

          <p className="small">* QVR Employee Costs are an estimated 20% of your currently entered employee costs based on the workload transfered to QVR's software. </p>
          {/* <p className="small">* A conservative estimate is that your organization will be at least 50% more efficient at QVR tasks </p> */}
        </div>
      </div>
    </>

    // ARROW ONLY SAVES ON HRM, VILLAGE AND CASTLE SAVE ON OTHERS*****************************************
    // FACTOR IN PRICING-20% OF WHAT THE APP SAVES
  )
}

export default CombinedPricingElements
