import React, { useState, useEffect } from 'react'
import CalculatorForm from './savings-calculator-form'
import PackageSelection from './package-selection';

function SavingsCalculator( props ) {

  
  const [emarTotal, setEmarTotal] = useState(0)
  const [hrmTotal, setHrmTotal] = useState(0)
  const [ehrTotal, setEhrTotal] = useState(0)
  const [overalTotal, setOveralTotal] = useState(0)
  const [combinedTotal, setCombinedTotal] = useState(0)
  const [qvrPerc, setQvrPerc] = useState(50)
  const [empPerc, setEmpPerc] = useState(50)
  const [emarPerc, setEmarPerc] = useState(33)
  const [hrmPerc, setHrmPerc] = useState(33)
  const [ehrPerc, setEhrPerc] = useState(34)
  const [totalSavings, setTotalSavings] = useState(0)

  const [clntAmnt, setClntAmnt] = useState(props.pricingData.numClients);
  const [selectedPackage, setSelectedPackage] = useState(props.pricingData.selectedPackage)

  useEffect(() => {
    let qvrTotal = props.pricingData.price + props.pricingData.implementationPrice
    let sum = emarTotal + hrmTotal + ehrTotal
    let emar = (emarTotal / sum) * 100
    let hrm = (hrmTotal / sum) * 100
    let ehr = (ehrTotal / sum) * 100
    let emp = (overalTotal * .2)

    let qvrSum = (qvrTotal + emp)
    let qvr = (qvrTotal / (qvrSum)) * 100
    let qvrEmp = (emp / qvrSum) * 100
    let com = (qvrTotal + (sum * .2))

    let tot = (overalTotal - com)

    // let sav = (overalTotal > 0 ? overalTotal - combinedTotal : 0)
    // let savPerc = (overalTotal > 0 ? overalTotal / (overallTotal + combinedTotal) : 0)
    // setTotalSavings(sav)
    // setTotalSavingsPerc(savPerc)

    setEmarPerc(emar)
    setHrmPerc(hrm)
    setEhrPerc(ehr)
    setOveralTotal(sum)
    setQvrPerc(qvr)
    setCombinedTotal(com)
    setEmpPerc(qvrEmp)
    setTotalSavings(tot)

    setClntAmnt(props.pricingData.numClients)
  }, [emarTotal, hrmTotal, ehrTotal, props.pricingData])

  return (
    <>
      <div className="card bg-light p-5">
        {/* Title */}
        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-2">
          <h2 className="text-primary">Savings <span className="font-weight-semi-bold">calculator</span>:</h2>

          {/* TODO: The primary objective of QVR is to automate processes that do not require a human. For example, how much time do you, or an employee of yours, spend auditing client files? How much time do you or an employee spend verifying that a MAR was signed correctly. What about HR files and general compliance? With sofisticated software that is constantly checking and re-checking your files, the amount of time your administrative team spends on these tasks will be significantly reduced. Thus, when using this calculator, think of your team and the amount of time they spend doing tasks that QVR can do for you. */}
        </div>
        {/* End Title */}

        {/* Stats */}
        <div className="row mb-2 mb-lg-0">
          <div className="col-lg-9 mb-2 mb-lg-0">
            {/* E.M.A.R. Title */}
            <div className="row">
              <div className="col">
                <h4 className="text-primary">Your E.M.A.R. <span className="font-weight-semi-bold">Costs</span></h4>
              </div>
            </div>
            {/* End E.M.A.R. Title */}

            {/* Calculator Form */}
            <CalculatorForm fieldData={setEmarTotal} />
            {/* End Calculator Form */}

            {/* H.R.M Title */}
            <div className="row">
              <div className="col">
                <h4 className="text-primary">Your H.R.M. <span className="font-weight-semi-bold">Costs</span></h4>
              </div>
            </div>
            {/* End H.R.M Title */}

            {/* Calculator Form */}
            <CalculatorForm fieldData={setHrmTotal} />
            {/* End Calculator Form */}

            {/* E.H.R Title */}
            <div className="row">
              <div className="col">
                <h4 className="text-primary">Your E.H.R. <span className="font-weight-semi-bold">Costs</span></h4>
              </div>
            </div>
            {/* End E.H.R Title */}

            {/* Calculator Form */}
            <CalculatorForm fieldData={setEhrTotal} />
            {/* End Calculator Form */}

            {/* Package Selector */}
            <div className="row">
              <div className="col">
                <h4 className="text-primary">Your QVR <span className="font-weight-semi-bold">Packages</span></h4>
              </div>
            </div>
            {/* End Package Selector */}

            {/* Calculator Form */}
            {/* <PackageSelection pricingData={props.pricingData} /> */}

            {/* Package Selection */}
            <div className="row">
              <div className="col-xl-3 col-lg-6 mb-4">
                {/* Input */}
                <label className="form-label">Number of Clients</label>
                <div className="js-focus-state input-group">
                  <input className="form-control" type="number" value={clntAmnt} onChange={e => setClntAmnt(e.target.value)} placeholder='Clients' />
                </div>
                {/* End Input */}
              </div>

              <div className="col-xl-3 col-lg-6 mb-4">
                {/* Button */}
                <label className="form-label">$10 Per Client</label>
                <button type="button" className={
                  selectedPackage === 'arrow'
                    ? `btn btn-md btn-block btn-soft-primary transition-3d-hover active`
                    : `btn btn-md btn-block btn-soft-primary transition-3d-hover`
                }
                >Arrow</button>
                {/* End Button */}
              </div>

              <div className="col-xl-3 col-lg-6 mb-4">
                {/* Button */}
                <label className="form-label">$15 Per Client</label>
                <button type="button" className={
                  selectedPackage === 'village'
                    ? `btn btn-md btn-block btn-soft-primary transition-3d-hover active`
                    : `btn btn-md btn-block btn-soft-primary transition-3d-hover`
                }
                >Village</button>
                {/* End Button */}
              </div>

              <div className="col-xl-3 col-lg-6 mb-4">
                {/* Button */}
                <label className="form-label">$18 Per Client</label>
                <button type="button" className={
                  selectedPackage === 'castle'
                    ? `btn btn-md btn-block btn-soft-primary transition-3d-hover active`
                    : `btn btn-md btn-block btn-soft-primary transition-3d-hover`
                }
                >Castle</button>
                {/* End Button */}
              </div>
            </div>
            {/* End Package Selection */}

            {/* End Calculator Form */}
          </div>

          <div className="col-lg-3">
            <div className="mb-4">
              <span className="h4 font-weight-medium">Current Costs ${(overalTotal).toFixed(2)}</span>
              <span className="h6 font-weight-medium">/Month</span>
            </div>
            {/* Progress */}
            <div className="progress mb-3" style={{ height: `.5rem` }}>
              <div className="progress-bar" role="progressbar" style={{ width: `${emarPerc}%`, ariaValuenow: `15`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
              <div className="progress-bar bg-success" role="progressbar" style={{ width: `${hrmPerc}%`, ariaValuenow: `30`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
              <div className="progress-bar bg-info" role="progressbar" style={{ width: `${ehrPerc}%`, ariaValuenow: `20`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
            </div>
            {/* End Progress */}

            {/* Fact List */}
            <ul className="list-unstyled font-size-1 mb-0">
              <li className="d-flex align-items-center py-1">
                <small className="far fa-circle text-primary mr-2"></small>
                <span className="font-weight-medium">E.M.A.R.</span>
                <div className="ml-auto">
                  <span className="text-secondary">${(emarTotal).toFixed(2)} /mo</span>
                </div>
              </li>

              <li className="d-flex align-items-center py-1">
                <small className="far fa-circle text-success mr-2"></small>
                <span className="font-weight-medium">H.R.M.</span>
                <div className="ml-auto">
                  <span className="text-secondary">${(hrmTotal).toFixed(2)} /mo</span>
                </div>
              </li>

              <li className="d-flex align-items-center py-1">
                <small className="far fa-circle text-info mr-2"></small>
                <span className="font-weight-medium">E.H.R.</span>
                <div className="ml-auto">
                  <span className="text-secondary">${(ehrTotal).toFixed(2)} /mo</span>
                </div>
              </li>
            </ul>
            {/* End Fact List */}

            <hr />

            {/* Savings Bar */}
            <div className="mt-6 mb-4">
              <span className="h4 font-weight-medium">QVR Costs ${(combinedTotal).toFixed(2)}</span>
              <span className="h6 font-weight-medium">/Month</span>
            </div>

            {/* Progress */}
            <div className="progress mb-3" style={{ height: `.5rem` }}>
              <div className="progress-bar" role="progressbar" style={{ width: `${qvrPerc}%`, ariaValuenow: `50`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
              <div className="progress-bar bg-success" role="progressbar" style={{ width: `${empPerc}%`, ariaValuenow: `50`, ariaValuemin: `0`, ariaValuemax: `100` }}></div>
            </div>
            {/* End Progress */}

            {/* Fact List */}
            <ul className="list-unstyled font-size-1 mb-0">
              <li className="d-flex align-items-center py-1">
                <small className="far fa-circle text-primary mr-2"></small>
                <span className="font-weight-medium">Software Cost</span>
                <div className="ml-auto">
                  <span className="text-secondary">${(combinedTotal).toFixed(2)} /mo</span>
                </div>
              </li>

              <li className="d-flex align-items-center py-1">
                <small className="far fa-circle text-indigo mr-2"></small>
                <span className="font-weight-medium">Employee Cost*</span>
                <div className="ml-auto">
                  <span className="text-secondary">${(overalTotal * .2).toFixed(2)} /mo</span>
                </div>
              </li>
            </ul>
            {/* End Fact List */}
            {/* End Savings Bar */}
            <hr />
            <div className="mt-6">
              <span className="h4 font-weight-medium">Total Savings ${(totalSavings).toFixed(2)}</span>
              <span className="h6 font-weight-medium">/Month</span>
            </div>
          </div>
        </div>
        {/* End Stats */}

        <p className="small">* QVR Employee Costs are an estimated 20% of your currently entered employee costs based on the workload transfered to QVR's software. </p>
        {/* <p className="small">* A conservative estimate is that your organization will be at least 50% more efficient at QVR tasks </p> */}
      </div>
    </>

    // ARROW ONLY SAVES ON HRM, VILLAGE AND CASTLE SAVE ON OTHERS*****************************************
    // FACTOR IN PRICING-20% OF WHAT THE APP SAVES
  )
}

export default SavingsCalculator
